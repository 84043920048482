import "tinymce/tinymce";
import 'tinymce/icons/default';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/spellchecker';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/skins/content/default/content.css';

$(document).ready(function() {
  let editor = null;
  function hideSpellCheckModal() {
    const modalElement = document.getElementById('spellCheckModal');
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }
  function showSpellCheckModal(options) {
    const modalElement = document.getElementById('spellCheckModal');
    const modalInstance = new bootstrap.Modal(modalElement);
    const modalBody = modalElement.querySelector('.modal-body');
    modalBody.innerHTML = options.message || '';
    modalInstance.show();
    if (options.onShow) {
      options.onShow();
    }
    modalElement.addEventListener('hidden.bs.modal', function(event) {
      if (options.onHidden) {
        options.onHidden(event);
      }
      modalBody.innerHTML = '';
    });
  }

  jQuery(document).on("click", ".spell_button", function(e) {
    const area_id = jQuery(e.target).data("textarea-id");
    const closeImageUrl = jQuery(e.target).data("closebtn-img");
    const imageUrl = jQuery(e.target).find("img").prop('src');
    const check_text_area = jQuery(`textarea[name='${area_id}'], input[name='${area_id}']`);
    const text_area_value = check_text_area.val();
    let text_area_lang = check_text_area.attr('language');

    if (text_area_lang === 'en') { text_area_lang = 'en-US'; }
    else if (text_area_lang === 'de') { text_area_lang = 'de-DE'; }

    function getTinyWidthValue() {
      return window.matchMedia('(min-width: 992px)').matches ? '760' : '465';
    }

    function isContentHtml() {
      let reg = /<(.|\n)*?>/g;
      return reg.test(text_area_value);
    }

    function checkForHtmlContent(ed) {
      ed.ui.registry.addButton('saveandclose', {
        text: `<img src="${closeImageUrl}" style="width: 16px !important;"> Save and close`,
        onAction: function() {
          let get_content_params = { format: 'text' };
          if (isContentHtml()) {
            get_content_params = {};
          }
          const opaopa = ed.getContent(get_content_params);
          check_text_area.val(opaopa);
          check_text_area.trigger("change");
          hideSpellCheckModal();
        }
      });
    }

    function initTiny() {
      tinyMCE.init({
        width: getTinyWidthValue(),
        height: '450',
        selector: '#checktext',
        plugins: ["paste", 'spellchecker'],
        paste_text_sticky: true,
        menubar: false,
        toolbar: 'checkspelling | saveandclose | spellchecker',
        toolbar_location: 'bottom',
        elementpath: false,
        statusbar: false,
        setup: function(ed) {
          editor = ed;
          ed.on('init', function() {
            ed.pasteAsPlainText = true;
            ed.insertContent(text_area_value.replace(/\n/g, "<br>"));
            ed.execCommand("mceSpellCheck", text_area_lang);
          });

          ed.ui.registry.addButton('checkspelling', {
            text: `<img src="${imageUrl}" style="width: 16px !important;"> Re-check spelling`,
            onAction: function() {
              ed.focus();
              ed.execCommand("mceSpellCheck", text_area_lang);
            }
          });

          checkForHtmlContent(ed);
        },
        /* translations: */
        languagetool_i18n_no_errors: {
          "de-DE": "Keine Fehler gefunden."
        },
        languagetool_i18n_explain: {
          "de-DE": "Mehr Informationen..."
        },
        languagetool_i18n_ignore_once: {
          "de-DE": "Hier ignorieren"
        },
        languagetool_i18n_ignore_all: {
          "de-DE": "Fehler dieses Typs ignorieren"
        },
        languagetool_i18n_rule_implementation: {
          'pl': 'Dodatkowe informacje o regule…',
          'ru': 'Описание реализации правила'
        },
        spellchecker_rpc_url: window.location.origin + "/spellcheck",
        spellchecker_languages: 'English=en,German=de',
        spellchecker_language: I18n.locale,
        skin: false,
        content_css: false,
        content_style: '.mce-spellchecker-word {text-decoration: underline red;}',
        theme_advanced_buttons1: "checkspelling,saveandclose",
        theme_advanced_buttons2: "",
        theme_advanced_buttons3: "",
        theme_advanced_toolbar_location: "bottom",
        theme_advanced_toolbar_align: "right",
        theme_advanced_statusbar_location: "none",
        theme_advanced_path: false,
        theme_advanced_resizing: true,
        theme_advanced_resizing_use_cookie: false,
        gecko_spellcheck: false
      });
    }
    showSpellCheckModal({
      message: '<div id="checktextpara"><textarea id="checktext" name="text" rows="10"></textarea></div>',
      onShow: function() {
        initTiny();
      },
      onHidden: function() {
        if (editor !== null) {
          editor.destroy();
        }
      }
    });
  });
});
