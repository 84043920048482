/* A polyfill for browsers that don't support ligatures. */
/* The script tag referring to this file must be placed before the ending body tag. */

/* To provide support for elements dynamically added, this script adds
   method 'icomoonLiga' to the window object. You can pass element references to this method.
*/
(function () {
    'use strict';
    function supportsProperty(p) {
        var prefixes = ['Webkit', 'Moz', 'O', 'ms'],
            i,
            div = document.createElement('div'),
            ret = p in div.style;
        if (!ret) {
            p = p.charAt(0).toUpperCase() + p.substr(1);
            for (i = 0; i < prefixes.length; i += 1) {
                ret = prefixes[i] + p in div.style;
                if (ret) {
                    break;
                }
            }
        }
        return ret;
    }
    var icons;
    if (!supportsProperty('fontFeatureSettings')) {
        icons = {
            'minus': '&#xea0b;',
            'subtract': '&#xea0b;',
            'qrcode': '&#xe938;',
            'cross': '&#xea0f;',
            'cancel': '&#xea0f;',
            'checkmark': '&#xea10;',
            'tick': '&#xea10;',
            'loop2': '&#xea2e;',
            'repeat2': '&#xea2e;',
            'filter': '&#xea5b;',
            'funnel': '&#xea5b;',
            'bold': '&#xea62;',
            'wysiwyg4': '&#xea62;',
            'underline': '&#xea63;',
            'wysiwyg5': '&#xea63;',
            'italic': '&#xea64;',
            'wysiwyg6': '&#xea64;',
            'paragraph-left': '&#xea77;',
            'wysiwyg25': '&#xea77;',
            'paragraph-center': '&#xea78;',
            'wysiwyg26': '&#xea78;',
            'paragraph-right': '&#xea79;',
            'wysiwyg27': '&#xea79;',
            'paragraph-justify': '&#xea7a;',
            'wysiwyg28': '&#xea7a;',
            'indent-increase': '&#xea7b;',
            'wysiwyg29': '&#xea7b;',
            'indent-decrease': '&#xea7c;',
            'wysiwyg30': '&#xea7c;',
            'share2': '&#xea83;',
            'social': '&#xea83;',
            'facebook': '&#xea90;',
            'brand10': '&#xea90;',
            'instagram': '&#xea92;',
            'brand12': '&#xea92;',
            'whatsapp': '&#xea93;',
            'brand13': '&#xea93;',
            'twitter': '&#xea96;',
            'brand16': '&#xea96;',
            'youtube': '&#xea9d;',
            'brand21': '&#xea9d;',
            'dribbble': '&#xeaa7;',
            'brand31': '&#xeaa7;',
            'behance': '&#xeaa8;',
            'brand32': '&#xeaa8;',
            'behance2': '&#xeaa9;',
            'brand33': '&#xeaa9;',
            'github': '&#xeab0;',
            'brand40': '&#xeab0;',
            'skype': '&#xeac5;',
            'brand60': '&#xeac5;',
            'linkedin': '&#xeac9;',
            'brand64': '&#xeac9;',
            'linkedin2': '&#xeaca;',
            'brand65': '&#xeaca;',
            'xing2': '&#xead4;',
            'brand75': '&#xead4;',
            'paypal': '&#xead8;',
            'brand79': '&#xead8;',
          '0': 0
        };
        delete icons['0'];
        window.icomoonLiga = function (els) {
            var classes,
                el,
                i,
                innerHTML,
                key;
            els = els || document.getElementsByTagName('*');
            if (!els.length) {
                els = [els];
            }
            for (i = 0; ; i += 1) {
                el = els[i];
                if (!el) {
                    break;
                }
                classes = el.className;
                if (/cww-icons/.test(classes)) {
                    innerHTML = el.innerHTML;
                    if (innerHTML && innerHTML.length > 1) {
                        for (key in icons) {
                            if (icons.hasOwnProperty(key)) {
                                innerHTML = innerHTML.replace(new RegExp(key, 'g'), icons[key]);
                            }
                        }
                        el.innerHTML = innerHTML;
                    }
                }
            }
        };
        window.icomoonLiga();
    }
}());
